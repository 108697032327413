export const CabinMoonlight = {
    mobile: require("./mobile/paw-print-cabin-moonlight-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-moonlight.webp"),
    alt: "Paw Print Cabin in moonlight"
}

export const CabinLivingRoom = {
    mobile: require("./mobile/paw-print-cabin-living-room-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-living-room.webp"),
    alt: "Paw Print Cabin - Living Room with 2 small couches and TV over a fireplace"
}

export const CabinBackPorchSummer = {
    mobile: require("./mobile/paw-print-cabin-back-porch-summer-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-back-porch-summer.webp"),
    alt: "Paw Print Cabin - back porch deck shown with propane grill and benches"
}

export const CabinBackPorchWinter = {
    mobile: require("./mobile/paw-print-cabin-back-porch-winter-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-back-porch-winter.webp"),
    alt: "Paw Print Cabin - back porch deck shown with propane grill and benches covered in snowfall"
}

export const CabinFirePit = {
    mobile: require("./mobile/paw-print-cabin-fire-pit-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-fire-pit.webp"),
    alt: "Paw Print Cabin - stone fire pit built in yard behind cabin"
}

export const CabinFrontPorchSunset = {
    mobile: require("./mobile/paw-print-cabin-front-porch-sunset-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-front-porch-sunset.webp"),
    alt: "Paw Print Cabin - sunset over cabin's front porch with entry steps and porch swing"
}

export const CabinLoftQueen = {
    mobile: require("./mobile/paw-print-cabin-loft-queen-bed-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-loft-queen-bed.webp"),
    alt: "Paw Print Cabin - loft featuring a queen bed, two nightstands with lamps, large rug, and rocking chair"
}

export const CabinLoftTwins = {
    mobile: require("./mobile/paw-print-cabin-loft-twin-beds-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-loft-twin-beds.webp"),
    alt: "Paw Print Cabin - loft featuring two twin beds built into the wall with barnwood ceilings"
}

export const CabinMasterKing = {
    mobile: require("./mobile/paw-print-cabin-master-bedroom-king-bed-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-master-bedroom-king-bed.webp"),
    alt: "Paw Print Cabin - master bedroom with king bed, quilted cover, live wood headboard, and personalized nightstands"
}

export const CabinStaircaseKitchen = {
    mobile: require("./mobile/paw-print-cabin-staircase-kitchen-mobile.webp"),
    desktop: require("./desktop/paw-print-cabin-staircase-kitchen.webp"),
    alt: "Paw Print Cabin - staircase leading from entryway to loft with refrigerator and oven of kitchen visible underneath"
}

export const SiloEntrance = {
    mobile: require("./mobile/owls-nest-silo-entrance-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-entrance.webp"),
    alt: "Owls Nest Silo - Covered entrance to the silo"
}

export const SiloBathroom = {
    mobile: require("./mobile/owls-nest-silo-bathroom-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-bathroom.webp"),
    alt: "Owls Nest Silo - Third floor bathroom entrance featuring two vanities, toilet, and large walk-in shower"
}

export const SiloBunkBeds = {
    mobile: require("./mobile/owls-nest-silo-bunk-beds-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-bunk-beds.webp"),
    alt: "Owls Nest Silo - Second floor guest room with bunked beds: a double on bottom and a twin on top"
}

export const SiloEntranceSign = {
    mobile: require("./mobile/owls-nest-silo-entrance-sign-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-entrance-sign.webp"),
    alt: "Owls Nest Silo - Hand made sign mounted outside of silo's front door"
}

export const SiloFirePit = {
    mobile: require("./mobile/owls-nest-silo-fire-pit-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-fire-pit.webp"),
    alt: "Owls Nest Silo - Stone fire pit situated in patio outside of silo"
}

export const SiloKitchen = {
    mobile: require("./mobile/owls-nest-silo-kitchen-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-kitchen.webp"),
    alt: "Owls Nest Silo - Kitchen in entryway featuring oven, stove, microwave, dishwasher, sink and cabinet space"
}

export const SiloLaundry = {
    mobile: require("./mobile/owls-nest-silo-laundry-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-laundry.webp"),
    alt: "Owls Nest Silo - Sliding door covering washer/dryer laundry machines and utility closet with kitchen fridge nearby"
}

export const SiloLivingRoomChairs = {
    mobile: require("./mobile/owls-nest-silo-living-room-chairs-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-living-room-chairs.webp"),
    alt: "Owls Nest Silo - First floor living room featuring two recliners with wall artwork and ceiling fan"
}

export const SiloLivingRoomStaircase = {
    mobile: require("./mobile/owls-nest-silo-living-room-staircase-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-living-room-staircase.webp"),
    alt: "Owls Nest Silo - First floor living room showing built in bench and table with staircase to upper floors"
}

export const SiloLivingRoomTV = {
    mobile: require("./mobile/owls-nest-silo-living-room-tv-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-living-room-tv.webp"),
    alt: "Owls Nest Silo - First floor living room with TV mounted over fireplace"
}

export const SiloMasterKingMural = {
    mobile: require("./mobile/owls-nest-silo-master-bedroom-king-bed-mural-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-master-bedroom-king-bed-mural.webp"),
    alt: "Owls Nest Silo - Fourth floor master bedroom with king bed and high vaulted ceiling with hand paited mural on back wall"
}

export const SiloPatioPorchSwing = {
    mobile: require("./mobile/owls-nest-silo-outdoor-patio-porch-swing-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-outdoor-patio-porch-swing.webp"),
    alt: "Owls Nest Silo - Outdoor patio with porch swing, grill, and firepit"
}

export const SiloShower = {
    mobile: require("./mobile/owls-nest-silo-shower-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-shower.webp"),
    alt: "Owls Nest Silo - Walk in shower with tiled floor and bench"
}

export const SiloStaircase = {
    mobile: require("./mobile/owls-nest-silo-staircase-mobile.webp"),
    desktop: require("./desktop/owls-nest-silo-staircase.webp"),
    alt: "Owls Nest Silo - Curved staircase with floor lighting and wood hand rail"
}

export const FurNFeathersTreeHouse = {
    mobile: require("./mobile/fur-n-feathers-lodging-tree-house-mobile.webp"),
    desktop: require("./desktop/fur-n-feathers-lodging-tree-house.webp"),
    alt: "Fur N Feathers Lodging Property - uncovered tree house built on trail from properties to a nearby creek"
}

export const FurNFeathersFlintHills = {
    mobile: require("./mobile/fur-n-feathers-lodging-flint-hills-scenery-mobile.webp"),
    desktop: require("./desktop/fur-n-feathers-lodging-flint-hills-scenery.webp"),
    alt: "Fur N Feathers Lodging Property - Scenic view of both properties from across nearby field and plains"
}

export const MiddleCreekFall = {
    mobile: require("./mobile/middle-creek-fall-mobile.webp"),
    desktop: require('./desktop/middle-creek-fall.webp'),
    alt: "Middle Creek - Nearby creek to Fur N Feathers Lodging Properties"
}

export const FlintHillsGrass = {
    mobile: require("./mobile/flint-hills-kansas-native-grasses-sunset-mobile.webp"),
    desktop: require("./desktop/flint-hills-kansas-native-grasses-sunset.webp"),
    alt: "Flint Hills - Kansas Native Grasses"
}

export const FlintHillsPond = {
    mobile: require("./mobile/flint-hills-pond-mobile.webp"),
    desktop: require("./desktop/flint-hills-pond.webp"),
    alt: "Flint Hills - Pond under purple sunset and rolling fields"
}

export const FlintHillsHighway = {
    mobile: require("./mobile/flint-hills-highway-scenery-mobile.webp"),
    desktop: require("./desktop/flint-hills-highway-scenery.webp"),
    alt: "Flint Hills - Highway scenery"
}

export const FlintHillsSunrise = {
    mobile: require("./mobile/flint-hills-sunrise-mobile.webp"),
    desktop: require("./desktop/flint-hills-sunrise.webp"),
    alt: "Flint Hills - Beautiful sunrise"
}

export const FurNFeathersFamily = {
    mobile: require("./mobile/fur-n-feathers-lodging-family-photo-mobile.webp"),
    desktop: require("./desktop/fur-n-feathers-lodging-family-photo.webp"),
    alt: "Fur N Feathers - Wiens Family Photo"
}