import React from 'react';
import Sidebar from './Sidebar'
import siteLogo from '../images/logos/FurNFeathers Logo_Transparent.png'
import SocialMediaLinks from './SocialMediaLinks';
import { makeStyles } from '@material-ui/core';
import { FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Picture from './Picture';
import { BOOKING_SUBDOMAIN } from '../constants';



const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  nav: {
    position: 'fixed',
    height: '120px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 12,
    "& button": {
      color: 'white', 
      border: '1px solid white'
    }
  },
  image: {
    top: 0,
    left: 0,
    position: 'relative',
    width: '100%',
    "& img": {
      objectFit: 'cover',
      objectPosition: 'center',
      height: '70vh',
      width: '100%',
      "@media screen and (max-width: 600px)": {
        height: '40vh'
      },
    }
  },
  textOverlay: {
    position: 'absolute',
    color: 'white', 
    fontSize: '64px', 
    marginLeft: '15vw', 
    marginRight: '25vw',
    marginTop: '25vh',
    // textShadow: "1px 1px 0 #333,-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,2px 2px 5px rgba(0,0,0,0.65)",
    "@media screen and (max-width: 1000px)": {
      fontSize: '48px',
      marginLeft: '10vw',
      marginRight: '10vw',
      marginTop: '15vh'
    },
    "@media screen and (max-width: 600px)": {
      fontSize: '32px',
      marginLeft: '5vw',
      marginRight: '5vw',
      marginTop: '10vh'
    },
  },
  bars: {
    display: "none",
    color: "white",
    "@media screen and (max-width: 600px)": {
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      transform: "translate(-100%, 75%)",
      fontSize: "1.8rem",
      cursor: "pointer",
    }
  },
  navMenu: {
    display: "flex",
    alignItems: "start",
    paddingLeft: "32px",
    "& a": {
      color: "white",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      padding: "0 1rem",
      height: "100%",
      cursor: "pointer",
      "&.active": {
        color: "#808080"
      },
      "&:hover": {
        color: "#808080"
      },
      "&:not(:first-of-type)": {
        "@media screen and (max-width: 600px)": {
          display: "none"
        },
      }

    },
    "& img": {
      "@media screen and (max-width: 900px)": {
        width: '100px',
        alignSelf: 'start',
        paddingTop: '16px',
        paddingRight: 0,
      },
      "@media screen and (max-width: 1200px)": {
        paddingRight: "5vw"
      },
      paddingRight: "10vw"
    }
  },
  finalSection: {
    display: 'flex', 
    justifyContent: 'end', 
    alignItems: 'center', 
    paddingRight: '32px', 
    columnGap: '32px',
    "@media screen and (max-width: 900px)": {
      display: "none"
    },
  }
}))
  
const PageHeader = ({backgroundImage, overlayText}) => {

  let classes = useStyles();

  const [sidebarOpen, setSidebarOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const pageHeight = window.innerHeight

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
  };

  React.useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <header className={classes.container}>
      <nav className={classes.nav} style={{backgroundColor: `rgba(42, 40, 41,${scrollPosition/pageHeight})`}}>
        <FaBars className={classes.bars} onClick={handleDrawerToggle}/>
        <Sidebar isOpen={sidebarOpen} handleDrawerToggle={handleDrawerToggle}/>
  
        <div className={classes.navMenu}>
          <NavLink to='/'>
            <img src={siteLogo} width="200" alt="Fur 'N Feathers Logo"/>
          </NavLink>
          <NavLink to='/blog'>
            Blog
          </NavLink>
          <NavLink to='/about-us'>
            About Us
          </NavLink>
          <NavLink to='/FAQ'>
            FAQ
          </NavLink>
        </div>
        <div className={classes.finalSection}>
          <SocialMediaLinks/>
          <a href={BOOKING_SUBDOMAIN} target="_blank" rel="noreferrer"><button>Book Now</button></a>
        </div>
      </nav>
      {
        !!backgroundImage 
        ? <Picture src={backgroundImage} className={classes.image}/>
        : null
      }
      {!!overlayText ? <h1 className={classes.textOverlay}>{overlayText}</h1>: null}
    </header>
  );
};
  
export default PageHeader;