import React from 'react';

function Picture({src, imgProps, id, className}) {
    return (
        <picture id={id} className={className}>
            <source media="(max-width: 900px)" srcSet={src.mobile}/>
            <source media="(min-width: 901px)" srcSet={src.desktop}/>
            <img src={src.mobile} alt={src.alt} {...imgProps}/>
        </picture>
    )
}

export default Picture