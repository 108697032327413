import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import { NavLink } from 'react-router-dom';
import { API_ROUTE, COLORS } from '../constants';
import { Field, Form, Formik } from 'formik';
import siteLogo from '../images/logos/FurNFeathers Logo_Transparent.png'
import SocialMediaLinks from './SocialMediaLinks';
import axios from 'axios';

const useStyles = makeStyles(() => ({
    footerContainer: {
        backgroundColor: COLORS.footerBackground,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '32px 15vw',
        '& h2': {
            color: COLORS.footerSecondaryText
        },
        '& p': {
            color: COLORS.footerSecondaryText
        },
    },
    footerLeft: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'start',
        "& #copyright": {
            marginTop: "auto",
        },
        "& #tagline": {
            maxWidth: '230px'
        }
    },
    footerCenter: {
        textAlign: 'center', 
        '& input': {
            width: '75%',
            padding: '12px 20px',
            margin: '8px 0',
            boxSizing: 'border-box',
            backgroundColor: COLORS.footerSecondaryText,
            '&::placeholder': {
                color: COLORS.footerPrimaryText,
                opacity: 0.5
            },
            "&:hover": {
                backgroundColor: COLORS.footerPrimaryText,
                "&::placeholder": {
                    color: COLORS.footerSecondaryText,
                    opacity: 0.5
                }
            }
        },
        "& button": {
            padding: '10px 20px',
            "&:hover": {
                color: 'white',
                borderColor: 'white'
            }
        }
    },
    footerRight: {
        textAlign: 'end',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'end',
        '& .MuiGrid-item': {
            marginBottom: 'auto',
            display: 'flex',
            justifyContent: 'end',
            '& h4': {
                margin: 0
            },
            '& a': {
                color: COLORS.footerSecondaryText,
                textDecoration: 'none',
                cursor: 'pointer',
                '& .active': {
                  color: '#FFFFFF',
                },
                '& :hover': {
                  color: '#FFFFFF'
                }
            }
        },
    }
}));

function EmailConfirmationModal({isOpen, handleClose, handleYes, formValues}) {
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                {"Resubscribe with Existing Account?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Our records indicate that a subscriber with the email <b>{formValues.email}</b> already exists.
                    Would you like to resubscribe to update your name to <b>{formValues.name}</b>? 
                    (You will receive a confirmation email if you click Yes)
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={() => handleYes(formValues)}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}

function PageFooter() {
    const classes = useStyles();

    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [formValues, setFormValues] = React.useState({})

    function handleCloseDialog() {
        setIsDialogOpen(false)
    }

    const handleDialogYes = async (values) => {
        await axios.post(`${API_ROUTE}/addEmailSubscriber`, values)
        await axios.post(`${API_ROUTE}/sendSubscriptionConfirmation`, values)
        setFormValues({})
        setIsDialogOpen(false)
    }

    const handleEmailSubscribe = async (values, actions) => {
        actions.resetForm();
        try {
            const response = await axios.post(`${API_ROUTE}/testExistingEmail`, values)
            if (response.data.code === 404) {
                await axios.post(`${API_ROUTE}/addEmailSubscriber`, values)
                await axios.post(`${API_ROUTE}/sendSubscriptionConfirmation`, values)
            }
            else if (response.status === 200) {
                setFormValues(values)
                setIsDialogOpen(true)
            }
            else {
                return;
            }
        } catch (error) {
            return;
        }
    }

    return (
        <footer>
        <Grid container className={classes.footerContainer}>
            <Box component={Grid} item display={{xs: 'none', md: 'flex'}} md={4} className={classes.footerLeft}>
                <img src={siteLogo} width="150px" height="85px" alt="Fur N Feathers Logo"/>
                <p id="tagline">TWO UNIQUE RETREATS IN PICTURESQUE FLINT HILLS</p>
                <p id="copyright">© 2023, Paw Print Cabin LLC</p> 
            </Box>
            <Grid item xs={12} md={4} className={classes.footerCenter}>
                <h2>Keep up with Fur N Feathers</h2>
                <Formik
                    initialValues={{ name: '', email: ''}}
                    onSubmit={handleEmailSubscribe}
                >
                    <Form>
                        <Grid container>
                            <Grid item xs={12}>
                                <Field type="text" name="name" placeholder="Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <Field type="email" name="email" placeholder="Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <button type="submit">Subscribe</button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>
            <Box component={Grid} item display={{xs: 'none', md: 'flex'}} md={4} className={classes.footerRight}>
                <h2>Quick Links</h2>
                <Grid container>
                    <Grid item xs={12}>
                        <NavLink to='/blog'>
                            <h4>Blog</h4>
                        </NavLink>
                    </Grid>
                    <Grid item xs={12}>
                        <NavLink to='/about-us'>
                            <h4>About Us</h4>
                        </NavLink>
                    </Grid>
                    <Grid item xs={12}>
                        <NavLink to='/FAQ'>
                            <h4>FAQ</h4>
                        </NavLink>
                    </Grid>
                </Grid>
                <SocialMediaLinks/>
            </Box>
        </Grid>
        {isDialogOpen && <EmailConfirmationModal isOpen={isDialogOpen} handleClose={handleCloseDialog} handleYes={handleDialogYes} formValues={formValues}/>}
        </footer>
    )
}

export default PageFooter