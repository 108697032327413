import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages'
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import FAQ from './pages/FAQ';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={Home()} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/blog' element={<Blog/>} />
        <Route path='/FAQ' element={<FAQ/>} />
      </Routes>
    </Router>
  );
}
  
export default App;
