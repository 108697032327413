import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Modal } from '@mui/material'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import stopEventPropagation from '../utils/stopEventPropagation'
import Picture from './Picture'

const useStyles = makeStyles(() => ({
    container: {
        height: '100vh',
        width: '100vw',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    currentImage: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        "& img": {
            maxHeight: '60vh',
            maxWidth: '100%',
        },
        userSelect: 'none'
    },
    imageList: {
        position: 'absolute',
        bottom: "5%",
        width: '90%',
        overflowX: 'auto',
        "& div": {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        "& img": {
            objectFit: 'contain', 
            maxHeight: '10vh', 
        },
        "& picture": {
            border: '2px solid gray', 
            margin: '8px',
            cursor: 'pointer',
            "&:first-child": {
                margin: '8px 8px 8px auto'
            },
            "&:last-child": {
                margin: '8px auto 8px 8px'
            }
        },
        "& #selected": {
            border: '8px solid gray',
            margin: '2px',
            "&:first-child": {
                margin: '2px 2px 2px auto'
            },
            "&:last-child": {
                margin: '2px auto 2px 2px'
            }
        }
    },
    logoStyle: {
        color: 'white',
        position: 'absolute',
        height: '80px',
        width: '40px',
        "@media screen and (max-width: 600px)": {
            height: '50px', 
            width: '25px'
        },
        cursor: 'pointer',
        alignSelf: 'center',
        padding: '0 4px',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 0.3)'
        },
        "&:first-of-type": {
            left: 0
        },
        "&:last-of-type": {
            right: 0
        }
    },
    fadeIn: {
        animation: `$fadeIn 600ms ease-in-out`,
    },
    fadeOut: {
        animation: `$fadeOut 600ms ease-in-out`,
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
}))

function ImageList({imageList, selectedImage, handleImageClick}) {
    return (
        <div>
            {imageList.map((img, index) => (
                <Picture 
                    src={img}
                    imgProps={{
                        onClick: (event) => {
                            stopEventPropagation(event);
                            handleImageClick(index);
                        },
                    }}
                    id={selectedImage.mobile === img.mobile ? "selected" : ""}
                    key={img.mobile}
                />
            ))}
        </div>
    )
}

function ImagePopup({open, onClose, currentImageIndex, setCurrentImageIndex, imageList}) {

    const classes = useStyles()

    const [currentImage, setCurrentImage] = React.useState(imageList[currentImageIndex])
    const [shallowIndex, setShallowIndex] = React.useState(currentImageIndex)
    const initialArrow = window.matchMedia("(hover: none)").matches
    const [showArrows, setShowArrows] = React.useState(initialArrow)
    const [isFading, setIsFading] = React.useState(false)

    React.useEffect(() => {
        setCurrentImage(imageList[shallowIndex])
    }, [shallowIndex, imageList])

    React.useEffect(() => {
        const nextImg = new Image()
        const prevImg = new Image()
        let prevIndex;
        if (shallowIndex === 0) {
            prevIndex = imageList.length - 1
        }
        else {
            prevIndex = shallowIndex - 1
        }
        let nextIndex;
        if (shallowIndex === imageList.length - 1) {
            nextIndex = 0
        }
        else {
            nextIndex = shallowIndex + 1
        }
        if (window.innerWidth <= 900) {
            nextImg.src = imageList[nextIndex].mobile
            prevImg.src = imageList[prevIndex].mobile
        }
        else {
            nextImg.src = imageList[nextIndex].desktop
            prevImg.src = imageList[prevIndex].desktop    
        }
    }, [shallowIndex, imageList])

    function handleImageClick(index) {
        setIsFading(true)
        setTimeout(() => {
            setShallowIndex(index)
            setIsFading(false)
        }, 500)
    }

    function handleHover() {
        setShowArrows(true)
    }

    function handleHoverLeave() {
        setShowArrows(false)
    }

    function handlePreviousClick() {
        setIsFading(true)
        setTimeout(() => {
            if (shallowIndex === 0) {
                setShallowIndex(imageList.length - 1)
            }
            else {
                setShallowIndex(shallowIndex - 1)
            }
            setIsFading(false)
        }, 500)
    }

    function handleNextClick() {
        setIsFading(true)
        setTimeout(() => {
            if (shallowIndex === imageList.length - 1){
                setShallowIndex(0)
            }
            else {
                setShallowIndex(shallowIndex + 1)
            }
            setIsFading(false)
        }, 500)
    }

    function handleClose() {
        setCurrentImageIndex(shallowIndex)
        onClose()
    }

    function handleKeyDown(event) {
        if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
            handlePreviousClick();
        }
        if (event.key === "ArrowRight" || event.key === "ArrowUp") {
            handleNextClick();
        }
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <div className={classes.container} onClick={handleClose} onKeyDown={handleKeyDown}>
                <div 
                    className={classes.currentImage}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHoverLeave}
                >
                    {showArrows && (
                        <FaAngleLeft 
                            onClick={(event) => {
                                stopEventPropagation(event);
                                handlePreviousClick();
                            }} 
                            className={classes.logoStyle}/>
                    )}
                    <Picture 
                        src={currentImage}
                        imgProps={{className: isFading ? classes.fadeOut : classes.fadeIn}}
                    />
                    {showArrows && (
                        <FaAngleRight 
                            onClick={(event) => {
                                stopEventPropagation(event);
                                handleNextClick();
                            }} 
                            className={classes.logoStyle}/>
                    )}
                </div>
                <div className={classes.imageList}>
                    <ImageList 
                        imageList={imageList} 
                        selectedImage={currentImage}
                        handleImageClick={handleImageClick}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ImagePopup