import React from 'react'
import { makeStyles } from '@material-ui/core'
import instagram from '../images/logos/instagram.png'
import facebook from '../images/logos/facebook.png'
import pinterest from '../images/logos/pinterest.png'
import tiktok from '../images/logos/tiktok.png'
import youtube from '../images/logos/youtube.png'


const useStyles = makeStyles(() => ({
    iconsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > div': {
            backgroundColor: 'transparent',
            padding: 8,
            cursor: 'pointer'
        }
    }
}))

function SocialMediaLinks() {
    const classes = useStyles()
    return (
        <div className={classes.iconsContainer}>
            <div>
                <a href="https://www.facebook.com/pawprintcabin" target="_blank" rel="noreferrer">
                    <img src={facebook} width='30px' alt="Facebook icon"/>
                </a>
            </div>
            <div>
                <a href="https://www.instagram.com/furnfeatherslodging/" target="_blank" rel="noreferrer">
                    <img src={instagram} width='30px' alt="Instagram icon"/>
                </a>
            </div>
            <div>
                <a href="https://www.tiktok.com/@furnfeatherslodging" target="_blank" rel="noreferrer">
                    <img src={tiktok} width='30px' alt="TikTok icon"/>
                </a>            
            </div>
            <div>
                <a href="https://www.youtube.com/channel/UCbg14NgBI7hANF4t0Uh6QrA" target="_blank" rel="noreferrer">
                    <img src={youtube} width='30px' alt="Youtube icon"/>
                </a>            
            </div>
            <div>
                <a href="https://www.pinterest.com/furnfeatherslodging/" target="_blank" rel="noreferrer">
                    <img src={pinterest} width='30px' alt="Pinterest icon"/>
                </a>            
            </div>
        </div>
    )
}

export default SocialMediaLinks