import { CabinBackPorchSummer, CabinBackPorchWinter, CabinFirePit, CabinFrontPorchSunset, CabinLivingRoom, CabinLoftQueen, CabinLoftTwins, CabinMasterKing, CabinMoonlight, CabinStaircaseKitchen, FurNFeathersFlintHills, FurNFeathersTreeHouse, MiddleCreekFall, SiloBathroom, SiloBunkBeds, SiloEntrance, SiloEntranceSign, SiloFirePit, SiloKitchen, SiloLaundry, SiloLivingRoomChairs, SiloLivingRoomStaircase, SiloLivingRoomTV, SiloMasterKingMural, SiloPatioPorchSwing, SiloShower, SiloStaircase } from './Pictures'

export const CabinScroller = [
    CabinLivingRoom,    
    CabinMoonlight,
    CabinBackPorchSummer,
    CabinBackPorchWinter,
    CabinFirePit,
    CabinFrontPorchSunset,
    CabinLoftQueen,
    CabinLoftTwins,
    CabinMasterKing,
    CabinStaircaseKitchen
]

export const SiloScroller = [
    SiloEntrance,
    SiloEntranceSign,
    SiloKitchen,
    SiloLaundry,
    SiloLivingRoomStaircase,
    SiloLivingRoomChairs,
    SiloLivingRoomTV,
    SiloBunkBeds,
    SiloBathroom,
    SiloShower,
    SiloMasterKingMural,
    SiloStaircase,
    SiloPatioPorchSwing,
    SiloFirePit
]

export const BothPropertiesScroller = [
    FurNFeathersFlintHills,
    FurNFeathersTreeHouse,
    MiddleCreekFall,
    ...CabinScroller,
    ...SiloScroller
]

