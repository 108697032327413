import React from 'react';
import axios from 'axios';
import PageLayout from '../components/PageLayout';
import { FlintHillsHighway } from '../images/Pictures';
import { makeStyles } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { Card, Grid } from '@mui/material';
import { API_ROUTE, PAGE_TAGLINES } from '../constants';
import LoadingIcon from '../components/LoadingIcon';
import ImagePopup from '../components/ImagePopup';

const useStyles = makeStyles(() => ({
    container: {
        padding: '0 10vw',
        "& #BlogLink": {
            padding: "32px 15vw",
            "@media screen and (max-width: 900px)": {
                padding: 0
            }
        }
    },
    blogContent: {
        padding: '0 10vw',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap: 'wrap',
        "& h1": {
            width: '100%',
            textAlign: 'center',
        },
        "& div": {
            "& p": {
                width: '100%',
                fontSize: '24px',
                margin: '0',
            },
        },
        "& img": {
            padding: '16px',
            width: '200px',
            height: '200px',
            objectFit: 'cover'
        }
    },
    monthlyBlogs: {
        display: 'flex',
        flexDirection: 'column',
        "& .MuiPaper-elevation": {
            borderRadius: '15px', 
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            margin: '12px 8px',
        },
        "& button": {
            border: 'none',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            padding: '4px 12px',
            width: '100%'
        },
        "& #selected": {
            backgroundColor: "#858585",
            "& button": {
                color: 'white'
            }
        },
        "@media screen and (max-width: 600px)": {
            flexDirection: 'row',
            width: '100%',
            overflowX: 'auto',
            paddingBottom: '4px',
            borderBottom: '1px solid #858585',
            "& .MuiPaper-elevation": {
                minWidth: '100px',
                "& button": {
                    fontSize: 12,
                    whiteSpace: 'nowrap'
                }
            },
        }
    },
    blogTiles: {
        alignItems: 'stretch',
        "& .MuiGrid-item": {
            padding: '20px',
            "& #tile": {
                cursor: 'pointer',
                borderRadius: '25px',
                // border: '1px solid black',
                display: 'flex', 
                flexDirection: 'column',
                height: '100%',
                "& > *:not(img)": {
                    padding: "8px 16px"
                },
                "& img": {
                    borderRadius: '25px',
                    width: '100%',
                    height: '300px',
                    objectFit: 'cover'
                }
            }
        }
    }
}))

function renderBlog(blog, isImagePopupOpen, handleImageClick, handleDialogClose, imageIndex) {

    const ImageWithOnClick = ({img, onClick}) => {
        return (
            <img 
                src={img.src}
                alt={img.alt}
                width={img.width}
                height={img.height}
                onClick={onClick}
            />
        )
    }

    // removes <a> tags surrounding <img> tags
    const noATags = blog.content.replaceAll(/<a(?=[^>]*><img)[^>]*>/g, '');
    const removedBreaks = noATags.replaceAll("<br />", " ")
    const removedImgs = removedBreaks.replaceAll(/<img(?=[^>]*>)[^>]*>/g, '')
    const finalContent = DOMPurify.sanitize(removedImgs)

    const parser = new DOMParser();
    const doc = parser.parseFromString(blog.content, "text/html");
    const imgTags = doc.querySelectorAll("img")
    const renderedImages = Array.from(imgTags).map((img, index) => (<ImageWithOnClick key={index} img={img} onClick={() => handleImageClick(index)}/>))

    const imgSrcs = Array.from(imgTags).map((img) => ({mobile: img.src, desktop: img.src, alt: img.alt}));


    return (
        <>
            <h1 onClick={() => handleImageClick(4)}>{!!blog.title ? blog.title : "No title"}</h1>
            <div dangerouslySetInnerHTML={{ __html: finalContent }}/>
            {renderedImages}
            {isImagePopupOpen && (
            <ImagePopup 
                open={isImagePopupOpen} 
                onClose={handleDialogClose} 
                currentImageIndex={imageIndex} 
                setCurrentImageIndex={(_) => null} 
                imageList={imgSrcs}/>
            )}
        </>
    )
}

function renderSummary(content) {
    const matches = content.match(/<p>(.*?)<\/p>/g)
    let index = 0
    let newText = ""
    do {
        let desiredText = matches[index].replace(/<\/?p>/g, "");
        let removedSpaces = desiredText.replaceAll("&nbsp;", " ")
        let removedBreaks = removedSpaces.replaceAll("<br />", " ")
        let removedSpans = removedBreaks.replace(/<\/?span[^>]*>/g, "")
        newText = removedSpans
        index += 1
    } while (newText.length < 20) 

    return newText.slice(0, 127)
}

function findImageSrc(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const img = doc.querySelector("img");
    const src = img.getAttribute("src");
    return src
}

function BlogsByMonth({blogs, className, onBlogMonthClick, selectedMonth}) {
    const monthData = blogs.map((blog) => {
        const date = new Date(blog.date);
        const month = date.toLocaleString('default', {month: 'short'})
        const year = date.getFullYear()
        return `${month} ${year}`
    })

    const extractedMonths = {}
    for (let month of monthData) {
        if (month in extractedMonths) {
            extractedMonths[month] += 1
        }
        else {
            extractedMonths[month] = 1
        }
    }

    return (
        <div className={className}>
            <Card raised={true} id={selectedMonth=== "ALL" ? "selected" : ''}><button onClick={() => onBlogMonthClick("ALL")}>ALL</button></Card>
            {Object.keys(extractedMonths).map((key) => (
                <Card raised={true} key={key} id={selectedMonth === key ? "selected" : ''}>
                <button onClick={() => onBlogMonthClick(key)} className=".MuiPaper-elevation">
                    <div style={{paddingRight: '16px'}}>{key}</div> 
                    <div>({extractedMonths[key]})</div>
                </button>
                </Card>
            ))}
        </div>
    )
}

function BlogTile({blog}) {
    const date = new Date(blog.date)

    return (
        <Card raised={true} id="tile">
            <img src={findImageSrc(blog.content)} alt="Blog header" loading='lazy'/>
            <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', marginTop: '16px'}}>
                <h2 style={{margin: 0}}>{date.toLocaleString('default', {day: 'numeric', month: 'short', year: 'numeric'})}</h2>
            </span>
            <h5>{renderSummary(blog.content)} . . . </h5>
        </Card>
    )
}

function BlogTiles({blogs, className, onBlogClick}) {
    return (
        <Grid container className={className}>
            {blogs.map((blog) => (
                <Grid item xs={12} md={6} lg={4} key={blog.date} onClick={() => onBlogClick(blog)}>
                    <BlogTile blog={blog}/>
                </Grid>
            ))}
        </Grid>
    )
}
  
function Blog() {
    const classes = useStyles()

    const [allBlogs, setAllBlogs] = React.useState([])
    const [filteredBlogs, setFilteredBlogs] = React.useState([])
    const [selectedBlog, setSelectedBlog] = React.useState(null)
    const [blogMonth, setBlogMonth] = React.useState("ALL")
    const [isLoadingBlogs, setIsLoadingBlogs] = React.useState(true)
    const [isImagePopupOpen, setIsImagePopupOpen] = React.useState(false)
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const getBlogs = async () => {
        const {data} = await axios.get(`${API_ROUTE}/getAllBlogs`)
        setAllBlogs(data)
        setIsLoadingBlogs(false)
    }

    React.useEffect(() => {
        getBlogs()
    },[])

    const filterBlogs = React.useCallback((inputMonth) => {
        if (inputMonth === "ALL") {
            setFilteredBlogs(allBlogs)
        }
        else {
            setFilteredBlogs(allBlogs.filter((blog) => {
                const date = new Date(blog.date);
                const month = date.toLocaleString('default', {month: 'short'})
                const year = date.getFullYear()
                return `${month} ${year}` === inputMonth
            }))
        }
    }, [allBlogs])

    React.useEffect(() => {
        filterBlogs(blogMonth)
    }, [allBlogs, blogMonth, filterBlogs])

    function handleBlogMonthClick(month) {
        filterBlogs(month)
        setSelectedBlog(null)
        setBlogMonth(month)
    }

    function handleBlogClick(blog) {
        setSelectedBlog(blog)
    }

    function handleDialogClose() {
        setIsImagePopupOpen(false)
    }

    function handleImageClick(index) {
        setCurrentImageIndex(index)
        setIsImagePopupOpen(true)
    }

    return (
        <PageLayout headerImage={FlintHillsHighway} overlayText={PAGE_TAGLINES.blog}>
            {isLoadingBlogs ? <LoadingIcon loadingText='Loading Blogs...'/> : (
            <Grid container className={classes.container}>
                <Grid item xs={12} id="BlogLink">
                    <h2>
                        Gerald Wiens documents all the activities at Fur N Feathers. 
                        Continue down to read through his blogs about the construction of Owls Nest Silo and other updates with our properties.
                        To see his blogger profile or leave comments on the posts, <a href="https://pawprintsiloconversionproject.blogspot.com/" target="_blank" rel="noreferrer">visit here!</a>
                    </h2>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <BlogsByMonth blogs={allBlogs} className={classes.monthlyBlogs} onBlogMonthClick={handleBlogMonthClick} selectedMonth={blogMonth}/>
                </Grid>
                <Grid item xs={12} sm={8} md={9} lg={10}>
                    {!!selectedBlog ? <div className={classes.blogContent}>{renderBlog(selectedBlog, isImagePopupOpen, handleImageClick, handleDialogClose, currentImageIndex)}</div> : (
                        <BlogTiles blogs={filteredBlogs} className={classes.blogTiles} onBlogClick={handleBlogClick}/>
                    )}
                </Grid>
            </Grid>
            )}
        </PageLayout>
    )
};
  
export default Blog;