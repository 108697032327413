import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
    "@keyframes underline": {
        "0%": {
          width: "0%",
        },
        "100%": {
          width: "100%",
        },
    },
    slideInRight: {
        opacity: 0,
        transform: 'translateX(25vw)',
        transition: 'opacity 1s, transform 1s',
    },
    slideInLeft: {
        opacity: 0,
        transform: 'translateX(-25vw)',
        transition: 'opacity 1s, transform 1s',
    },
    slideInTop: {
        opacity: 0,
        transform: 'translateY(-25vh)',
        transition: 'opacity 1s, transform 1s',
    },
    slideInBottom: {
        opacity: 0,
        transform: 'translateY(25vh)',
        transition: 'opacity 1s, transform 1s',
    },
    becomeVisible: {
        opacity: 1,
        transform: 'translateX(0)',
        "& #underline": {
            display: 'block',
            marginBottom: '16px', 
            height: '2px', 
            backgroundColor: 'black', 
            animation: '$underline 2000ms ease-in-out'
        }
    }
}))

function SlideInContainer({className, children, direction = "right", id = 'slide-in-text'}) {
    const classes = useStyles()

    const [isVisible, setIsVisible] = React.useState(false);

    const handleScroll = React.useCallback(() => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          if ((rect.top + rect.height/4) <= window.innerHeight) {
            setIsVisible(true);
          }
        }
    },[id])

    React.useEffect(() => {
        handleScroll()
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [handleScroll]);

    const slideInStyle = React.useMemo(() => {
        let slideClass = ""
        switch (direction) {
            case "right":
                slideClass = classes.slideInRight
                break;
            case "left":
                slideClass = classes.slideInLeft
                break;
            case "top":
                slideClass = classes.slideInTop
                break;
            case "bottom":
                slideClass = classes.slideInBottom
                break;
            default:
                slideClass = classes.slideInRight
                break;
        }
        return `${slideClass} ${isVisible ? classes.becomeVisible : ''}`
    }, [isVisible, classes, direction])

    return (
        <div className={`${className} ${slideInStyle}`} id={id}>
            {children}
        </div>
    )
}

export default SlideInContainer