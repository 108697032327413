import axios from 'axios';
import React from 'react'
import { API_ROUTE } from '../constants';

function GoogleMapImage() {
    const [imageUrl, setImageUrl] = React.useState("");

    const getUrl = async () => {
        const {data} = await axios.get(`${API_ROUTE}/getGoogleMapWidget`)
        setImageUrl(data)
    }

    React.useEffect(() => {
        getUrl()
    }, [])

    return (
        <iframe width="600" title="Google Maps" height="450" style={{border:0}} loading="lazy" allowFullScreen src={imageUrl}/>
    )
}

export default GoogleMapImage