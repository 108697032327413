export const COLORS = {
    footerBackground: '#2A2829',
    footerPrimaryText: '#D8D8D8',
    footerSecondaryText: '#858585',
    socialMedialBackground: '#D9D9D9'
}

export const API_ROUTE = process.env.REACT_APP_API_ROUTE

export const BOOKING_SUBDOMAIN = `https://${process.env.REACT_APP_BOOKING_SUBDOMAIN}`
export const CABIN_LISTING_NUMBER = '191167'
export const SILO_LISTING_NUMBER = '191168'
export const BOTH_LISTING_NUMBER = '191169'

export const PROPERTY_DESCRIPTIONS = {
    cabin: "Escape to the Paw Print Cabin near Elmdale, Kansas: a modern cabin completed in 2019, nestled on 40 acres of land that can sleep up to six. With a charming loft, access to Middle Creek for fishing and bird watching, and a serene wraparound porch overlooking the Flint Hills, it's an ideal retreat for relaxation. Explore remnants of an abandoned farmstead while enjoying modern amenities. Your unforgettable vacation begins here.",
    silo: "Welcome to the Owls Nest Silo, a unique 2-bedroom, 1.5-bathroom retreat crafted from a century-old, 45-foot tall concrete stave silo. Discover this enchanting stay in the heart of Flint Hills near Elmdale, Kansas, set on 40 acres with access to Middle Creek for fishing and bird watching. Unwind on the outdoor patio, embracing the tranquility of nature. Experience Flint Hills' allure like never before.",
    both: "Discover two distinctive properties in the scenic Flint Hills near Elmdale, Kansas. The Paw Print Cabin is a cozy 2-bedroom cabin with a loft, fireplace, and wraparound porch. The Owls Nest Silo is a remarkable 100 year old, 45-foot tall converted silo with 4 unique floors. Both properties are set on 40 acres of land with access to Middle Creek for fishing and bird watching. Explore the history and nature of Flint Hills at these charming retreats."
}

export const PAGE_TAGLINES = {
    home: "TWO UNIQUE RETREATS IN PICTURESQUE FLINT HILLS",
    blog: "LEARN WHAT FUR N FEATHERS IS UP TO TODAY!",
    faq: "QUESTIONS ABOUT OUR PROPERTIES?",
    aboutUs: "THE STORY OF A FAMILY-OWNED PROPERTY"
}