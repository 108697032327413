import React from 'react';
import PageLayout from '../components/PageLayout';
import { FlintHillsGrass, FurNFeathersFamily } from '../images/Pictures';
import { PAGE_TAGLINES } from '../constants';
import { makeStyles } from '@material-ui/core';
import Picture from '../components/Picture';

const useStyles = makeStyles(() => ({
    container: {
        padding: "16px 15vw",
        "& img": {
            float: 'right',
            width: '50%',
            aspectRatio: '3/2'
        },
        "@media screen and (max-width: 600px)": {
            padding: '16px 5vw',
            "& img": {
                width: '100%'
            }
        }
    }
}))
  
function AboutUs() {

    const classes = useStyles()

    return (
        <PageLayout headerImage={FlintHillsGrass} overlayText={PAGE_TAGLINES.aboutUs}>
            <div className={classes.container}>
                <h2>
                    Embracing Nature, Crafting Memories
                </h2>
                <p>
                    Greetings! We're Gerald and Jan Wiens, and our story is deeply intertwined with the beauty of Kansas and our unwavering passion for the natural world. Growing up in Marion County and Chase County, Kansas, we've always felt a profound connection to the land and its inhabitants. Our journey began in Arkansas City, where we raised our children and embraced careers that would set the stage for our unique venture.
                </p>
                <Picture src={FurNFeathersFamily}/>
                <h2>
                    Paw Print Cabin: A Testament to Love
                </h2>
                <p>
                    Our journey brought us to the heart of our heritage, where generations of memories had taken root. In 2017, the Wiens family embarked on a shared endeavor - Paw Print Cabin. Every plank, every nail, every detail was a labor of love. We were joined by family, extended relatives, our children Jennifer and Dustin, and the laughter of our cherished grandchildren - Jack, Carter, Ben, Emily, and Maura. This cabin, born from imagination and hard work, is a testament to our dedication and creativity. Completed in 2019, its unexpected popularity reshaped our plans and aspirations.
                </p>
                <h2>
                    Owls Nest Silo: Breathing New Life
                </h2>
                <p>
                    Inspired by the success of Paw Print Cabin and our boundless determination, we embarked on an even bolder project - Owls Nest Silo. This venture involved breathing new life into a century-old concrete stave silo that stood as a silent witness to generations. With each carefully planned conversion, we melded history with innovation. This was truly a family affair, with each member contributing their expertise to create a haven like no other. The journey was challenging, but the outcome - a unique rental property - has already captured hearts and garnered attention.
                </p>
                <h2>
                    Continuing the Legacy
                </h2>
                <p>
                    As we reflect on our journey, we're filled with gratitude for the opportunities that have come our way. Our dedication to creating spaces that blend harmoniously with nature has become our legacy. We invite you to join us in experiencing the tranquility of Paw Print Cabin and the pioneering spirit of Owls Nest Silo. These spaces are not just rentals; they're a chance to be part of our story, to forge your own memories, and to celebrate the beauty of life's unexpected twists. Welcome to our world!
                </p>
            </div>
        </PageLayout>
    )
};
  
export default AboutUs;