import { makeStyles } from '@material-ui/core';
import React from 'react';
import {VscLoading} from 'react-icons/vsc'

const useStyles = makeStyles(() => ({
    "@keyframes spin": {
        "0%": {
            transform: "rotate(360deg)",
        },
        "100%": {
            transform: "rotate(0deg)"
        }
    },
    container: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
    },
    icon: {
        width: '25vw',
        height: 'auto',
        verticalAlign: 'middle',
        animation: '$spin 2s infinite linear',
    },
    text: {
        "@media screen and (min-width: 900px)": {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
}))

function LoadingIcon({loadingText = "Loading..."}) {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <VscLoading className={classes.icon}/>
            <h1 className={classes.text}>{loadingText}</h1>
        </div>

    )
}

export default LoadingIcon