import React from 'react';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';

function PawprintLayout({headerImage, overlayText, children}) {
    return (
        <div>
            <PageHeader backgroundImage={headerImage} overlayText={overlayText}/>
            {children}
            <PageFooter/>
        </div>
    )
}
  
export default PawprintLayout;