import { makeStyles } from '@material-ui/core';
import React from 'react';
import { FaAngleLeft, FaAngleRight, FaExpand } from 'react-icons/fa';
import ImagePopup from './ImagePopup';
import Picture from './Picture';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        display: 'flex',
        "& picture": {
            width: '100%'
        },
        "& img": {
            objectFit: 'cover'
        },
        userSelect: 'none'
    },
    pictureCount: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(200, 200, 200, 0.5)'
    },
    expand: {
        position: 'absolute',
        bottom: 3,
        left: 3,
        color: 'rgba(255, 255, 255, 0.7)',
        cursor: 'pointer',
        width: '24px',
        height: '24px',
        "&:hover": {
            bottom: 0,
            left: 0,
            width: '30px',
            height: '30px',
            color: 'white'
        }
    },
    logoStyle: {
        color: 'rgba(200, 200, 200, 0.5)',
        filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))',
        position: 'absolute',
        height: '50px',
        width: '25px',
        cursor: 'pointer',
        alignSelf: 'center',
        padding: '0 4px',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'white'
        },
        "&:first-of-type": {
            left: 0
        },
        "&:last-of-type": {
            right: 0
        }
    },
    fadeIn: {
        animation: `$fadeIn 600ms ease-in-out`,
    },
    fadeOut: {
        animation: `$fadeOut 600ms ease-in-out`,
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
}))

function ImageScroller({imageList, startingIndex=0, width=""}) {

    const classes = useStyles();

    const [imageIndex, setImageIndex] = React.useState(startingIndex)
    const [currentImage, setCurrentImage] = React.useState(imageList[startingIndex])
    const initialArrow = window.matchMedia("(hover: none)").matches
    const [showArrows, setShowArrows] = React.useState(initialArrow)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [isFading, setIsFading] = React.useState(false)

    React.useEffect(() => {
        setCurrentImage(imageList[imageIndex])
    }, [imageIndex, imageList])

    React.useEffect(() => {
        const nextImg = new Image()
        const prevImg = new Image()
        let prevIndex;
        if (imageIndex === 0) {
            prevIndex = imageList.length - 1
        }
        else {
            prevIndex = imageIndex - 1
        }
        let nextIndex;
        if (imageIndex === imageList.length - 1) {
            nextIndex = 0
        }
        else {
            nextIndex = imageIndex + 1
        }
        if (window.innerWidth <= 900) {
            nextImg.src = imageList[nextIndex].mobile
            prevImg.src = imageList[prevIndex].mobile
        }
        else {
            nextImg.src = imageList[nextIndex].desktop
            prevImg.src = imageList[prevIndex].desktop    
        }
    }, [imageIndex, imageList])

    function handlePreviousClick() {
        setIsFading(true)
        let newIndex;
        if (imageIndex === 0) {
            newIndex = imageList.length - 1
        }
        else {
            newIndex = imageIndex - 1
        }
        setTimeout(() => {
            setImageIndex(newIndex)
            setIsFading(false)
        }, 500)
    }

    function handleNextClick() {
        setIsFading(true);
        let newIndex;
        if (imageIndex === imageList.length - 1) {
            newIndex = 0
        }
        else {
            newIndex = imageIndex + 1
        }
        setTimeout(() => {
            setImageIndex(newIndex)   
            setIsFading(false);
        }, 500);
    }

    function handleImageClick() {
        setIsDialogOpen(true)
    }

    function handleDialogClose() {
        setIsDialogOpen(false)
    }

    function handleHover() {
        setShowArrows(true)
    }

    function handleHoverLeave() {
        setShowArrows(false)
    }

    return (
        <>
            <span 
                className={classes.container} 
                style={{width: width}} 
                onMouseEnter={handleHover}
                onMouseLeave={handleHoverLeave}
            >
                {showArrows && <FaAngleLeft onClick={handlePreviousClick} className={classes.logoStyle}/>}
                <Picture 
                    src={currentImage} 
                    imgProps={{
                        loading: imageIndex !== 0 ? 'lazy' : 'eager', 
                        style: {aspectRatio: "1 / 1", width: width}, 
                        className: isFading ? classes.fadeOut : classes.fadeIn,
                        onClick: handleImageClick
                    }}
                />
                <small className={classes.pictureCount}>{`${imageIndex + 1} / ${imageList.length}`}</small>
                {showArrows && <FaExpand onClick={handleImageClick} className={classes.expand}/>}
                {showArrows && <FaAngleRight onClick={handleNextClick} className={classes.logoStyle}/>}
            </span>
            {isDialogOpen && (
            <ImagePopup 
                open={isDialogOpen} 
                onClose={handleDialogClose} 
                currentImageIndex={imageIndex} 
                setCurrentImageIndex={setImageIndex} 
                imageList={imageList}/>
            )}
        </>
    )
}

export default ImageScroller