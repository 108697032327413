import React from 'react'
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { FaHome, FaNewspaper, FaAddressBook, FaQuestionCircle } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { BOOKING_SUBDOMAIN, COLORS } from '../constants';
import SocialMediaLinks from './SocialMediaLinks';

function SidebarContent() {
    const links = [
        {URL: '/', title: "Home", logo: FaHome},
        {URL: '/blog', title: "Blog", logo: FaNewspaper},
        {URL: '/about-us', title: "About Us", logo: FaAddressBook},
        {URL: '/FAQ', title: "FAQ", logo: FaQuestionCircle},
    ]

    return (
        <List style={{height: '100dvh', padding: 0}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div>
                    {links.map((link) => (
                        <NavLink to={link.URL} style={{color: COLORS.footerSecondaryText, textDecoration: 'none'}} key={link.title}>
                        <ListItemButton key={link.title} divider sx={{minHeight: 65}}>
                            
                            <ListItemIcon>
                                <link.logo style={{color: COLORS.footerPrimaryText}}/>
                            </ListItemIcon>
                            <ListItemText primary={link.title}/>
                        </ListItemButton>
                        </NavLink>
                    ))}
                </div>
                <div>
                    <SocialMediaLinks/>
                    <a href={BOOKING_SUBDOMAIN} target="_blank" rel="noreferrer"><button>Book Now</button></a>
                </div>
            </div>
        </List>
    )
}

function Sidebar(props) {
    const { isOpen, handleDrawerToggle } = props

    return (
        <Drawer variant='temporary' open={isOpen} onClose={handleDrawerToggle} ModalProps={{
            keepMounted: true,
        }}
            sx={{
                display: {sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240,  },
            }}
            PaperProps={{sx: {backgroundColor: COLORS.footerBackground}}}
            >
            <SidebarContent />
        </Drawer>
    )
}


export default Sidebar